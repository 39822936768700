import { Injectable } from '@angular/core';
import { Platform, NavController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, from, of } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import { HttpService } from './http.service';
import { CommonService } from './common.service';
import { send } from 'process';

const TOKEN_KEY = 'ADMIN_DATA';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  adminToken = new BehaviorSubject(null);
  adminData = new BehaviorSubject(null);


  constructor(
    private platform: Platform,
    private router: Router,
    private nav: NavController,
    private alertController: AlertController,
    private httpService: HttpService,
    public commonService: CommonService,
  ) {
    this.platform.ready().then(() => {
      this.ifLoggedIn();
    });

  }

  ifLoggedIn() {
    let token = JSON.parse(localStorage.getItem(TOKEN_KEY));
    if (token) {
      this.adminToken.next(token);
      this.adminData.next(this.parseJwt(token));
    }
    // console.log(admin_data);
  }

  Logout() {
    this.adminToken.next(null);
    this.adminData.next(null);
    window.localStorage.removeItem(TOKEN_KEY);
    if (!this.router.url.includes('login')){
      window.location.href = './login';
    }
  }

  async Login(username: string, password: string) {    
    let send_data = {
      username: username,
      password: password
    }
    const login = await this.httpService.AdminLogin(send_data);
    console.log(login);
    if (login.result == "success" && login.data != null) {
      this.adminData.next(this.parseJwt(login.data));
      this.adminToken.next(login.data);
      localStorage.setItem(TOKEN_KEY, JSON.stringify(login.data));
      // let storageObs = from(this.storage.set(TOKEN_KEY, login.data));
      // return storageObs;
      return true;
    }
    else {
      this.commonService.openSnackBar("用戶名稱或密碼錯誤");
      return null;
    }
  }



  // directLogout() {
  //   this.adminToken.next(null);
  //   window.localStorage.removeItem("admin_data");
  //   this.commonService.OpenPage('/home');
  // }

  // SyncUserDate(event?) {
  //   if (this.getStoreAccountData() == null) {
  //     if (event != undefined) {
  //       event.target.complete();
  //     }
  //     return;
  //   }
  //   let send_data = {
  //     data_type: this.getStoreAccountData().data_type, 
  //     application_key: this.getStoreAccountData().application_key
  //   }
  //   this.httpService.GetSingleDataByDataTypeAndApplicationKey(send_data).then(res => {
  //     console.log(res);
  //     if (event != undefined) {
  //       event.target.complete();
  //     }
  //     if (res.result == "success" && res.data != null) {
  //       if (res.data.disabled) {
  //         this.commonService.openSnackBar("帳號已被停用", null, 5000);
  //         this.Logout();
  //         return;
  //       }
  //       if (res.data.password_data != this.getStoreAccountData().password_data) {
  //         this.commonService.openSnackBar("密碼已被更改，請重新登入", null,  5000);
  //         this.Logout();
  //         return;
  //       }
  //       this.updateUserData(res.data);
  //     }
  //     else {
  //       this.Logout();
  //     }
  //   });
  // }

  isAuthenticated() {
    return this.adminToken.value;
  }

  getToken(){
    return this.adminToken;
  }

  getAdminData() {
    return this.adminData.value;
  }

  updateAdminToken(token) {
    this.adminData.next(this.parseJwt(token));
    this.adminToken.next(token);
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  }


  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

}
