import { Injectable, Component, HostListener, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavController, MenuController, AlertController } from '@ionic/angular';
import { HttpService } from './http.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public isLoading = false;

  public dark_mode = false;

  constructor(
    private _snackBar: MatSnackBar,
    private nav: NavController,
    public menuCtrl: MenuController,
    private alertController: AlertController,
    private httpService: HttpService,
    public dialog: MatDialog
  ) {

  }

  remove_duplicate_from_array(arr) {
    return arr.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })
  }

  openSnackBar(message: string, button_text?: string, durantion?: number, horizontalPosition?, verticalPosition?) {
    this._snackBar.open(message, (button_text != undefined ? button_text : 'OK'), {
      duration: (durantion != undefined ? durantion : 2500),
      horizontalPosition: (horizontalPosition != undefined ? horizontalPosition : "end"),
      verticalPosition: (verticalPosition != undefined ? verticalPosition : "top"),
    });
  }

  openErrorSnackBar(message?: string, button_text?: string, durantion?: number, horizontalPosition?, verticalPosition?) {
    this._snackBar.open((message != undefined ? message : '出現錯誤'), (button_text != undefined ? button_text : 'OK'), {
      duration: (durantion != undefined ? durantion : 2500),
      horizontalPosition: (horizontalPosition != undefined ? horizontalPosition : "end"),
      verticalPosition: (verticalPosition != undefined ? verticalPosition : "top"),
      panelClass: "error_snackbar"
    });
  }


  firstFileToBase64(fileImage: File): Promise<{}> {
    return new Promise((resolve, reject) => {
      let fileReader: FileReader = new FileReader();
      if (fileReader && fileImage != null) {
        fileReader.readAsDataURL(fileImage);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject(new Error('No file found'));
      }
    });
  }

  downloadMedia(url, blank) {
    window.open((environment.env == 'dev' ? environment.dev_media_url : environment.prod_media_url) + url, '_blank');
  }

  openPage(url, blank, server_media) {
    console.log(url);
    if (server_media) {
      window.open((environment.env == 'dev' ? environment.dev_media_url : environment.prod_media_url) + url, (blank ? '_blank' : '_self'));
    }
    else {
      window.open(url, (blank ? '_blank' : '_self'));
    }

  }

  openCMSPage(url, type) {
    // console.log(window.location.origin);

    switch (type) {
      case 'blank':
        window.open(((window.location.origin.includes('localhost') ? window.location.origin : (environment.env == 'dev' ? environment.dev_cms_url : environment.prod_cms_url)) + url), '_blank');
        break;
      case 'self':
        window.open(((window.location.origin.includes('localhost') ? window.location.origin : (environment.env == 'dev' ? environment.dev_cms_url : environment.prod_cms_url)) + url), '_self');
        break;
      case 'forward':
        this.nav.navigateForward(url);
        break;
      case 'root':
        this.nav.navigateRoot(url);
        break;

      default:
        break;
    }
    // window.open((window.location.origin.includes('localhost') ? window.location.origin : window.location.origin + '/cms') + url, (blank ? '_blank' : '_self'));
  }

  getCMSPage(url) {
    console.log(window.location.origin);
    return ((window.location.origin.includes('localhost') ? window.location.origin : window.location.origin + '/cms') + url);
  }

  sort_new_to_old(array, key) {
    return array.sort(function (a, b) {
      var x = b[key]; var y = a[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  sort_old_to_new(array, key) {
    return array.sort(function (a, b) {
      var x = b[key]; var y = a[key];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }

  viewImage(element) {
    if (document.getElementById(element) != undefined && document.getElementById(element) != null) {
      document.getElementById(element).click();
    }
  }

  refresh() {
    window.location.reload();
  }

  async deleteDataAlert(data) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: "md",
      header: '確認刪除資料？(建議使用禁用功能)',
      message: '如確認刪除資料，所有跟此資料相關的其他資料，需自行刪除或修改。(例如刪除訂單時，請自行檢查是否需要刪除按金)',
      buttons: [
        {
          text: '是',
          handler: () => {
            this.deleteData(data);
          }
        },
        {
          text: '否',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alert.present();
  }

  // async deleteDataAlert(data) {
  //   if (this.isLoading) {
  //     return;
  //   }
  //   const dialogRef = this.dialog.open(ConfirmDialog);

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != undefined && result) {
  //       console.log(result);
  //       if (result == true) {
  //         // this.deleteData(data);
  //       }
  //     }
  //   });
  //   const alert = await this.alertController.create({
  //     header: '確定要刪除資料?',
  //     mode: 'md',
  //     message: '',
  //     cssClass: 'alertCss',
  //     buttons: [
  //       {
  //         text: '取消',
  //         role: 'cancel',
  //         handler: () => {
  //         }
  //       },
  //       {
  //         text: '是',
  //         handler: () => {
  //           this.deleteData(data);
  //         }
  //       }
  //     ]
  //   });
  //   await alert.present();
  // }


  deleteData(data) {
    let send_data = {
      id: data.id,
      data_type: data.data_type
    }
    this.isLoading = true;
    this.httpService.DeleteDataByDataTypeAndId(send_data).then(res => {
      console.log(res);
      this.isLoading = false;
      if (res.result == "success") {
        window.history.back();
      }
      else {
        this.openSnackBar("刪除資料失敗！");
      }
    });
  }

  GetDateTimeMatchBackendFormat(date: Date) {
    return (new Date(date.getTime() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -1).split(".")[0];
  }

  GetNthDaysAfter(date, days) {
    let d = new Date(new Date(date).setDate(new Date(date).getDate() + days));
    return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}T${date.slice(11, 19)}`;
  }

  GetNthDaysBefore(date, days) {
    let d = new Date(new Date(date).setDate(new Date(date).getDate() - days));
    return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}T${date.slice(11, 19)}`;
  }


  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateHongKongPhone(phone) {
    const re = /^-?(0|[1-9]\d*)?$/;
    return re.test(String(phone));
  }

  validateYearStringFormat(date) {
    //YYYY-YYYY
    const re = /^\d{4}-\d{4}$/;
    return re.test(String(date));
  }


  validateDateStringFormat(date) {
    //YYYY-MM-DD
    const re = /^\d{4}-\d{2}-\d{2}$/;
    return re.test(String(date));
  }

  validateHourMinuteStringFormat(date) {
    //HH:mm
    const re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return re.test(String(date));
  }

  validateHourMinuteWorkingHourStringFormat(date) {
    //HH:mm
    const re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return re.test(String(date));
  }

  validatePasswordFormat(date) {
    //Minimum eight characters, at least one letter and one number:
    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return re.test(String(date));
  }

  validateYYYYmmddFormat(date) {
    const re = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
    return re.test(String(date));
  }

  toggleDarkTheme() {
    document.body.classList.toggle('dark-theme', !this.dark_mode);
    this.dark_mode = !this.dark_mode;
    localStorage.setItem("dark_mode", this.dark_mode.toString());
  }

  getFileType(file_type) {
    switch (file_type) {
      case "image/jpeg":
        return "jpg";
      case "image/png":
        return "png";
      case "image/gif":
        return "gif";
      case "application/pdf":
        return "pdf";
      case "application/msword":
        return "doc";

      default:
        return "";
    }
  }

  checkTypeString(data) {
    return typeof data === 'string';
  }

  ToBackendDateString(date: Date) {
    return (new Date(date.getTime() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -1).split(".")[0];
  }

  media_url(){
    return (environment.env == 'dev' ? environment.dev_media_url : environment.prod_media_url);
  }
}


@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.html',
})
export class ConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>
  ) {
  }

  close() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }

}