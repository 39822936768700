import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';

import { IonMenu, MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { CommonService } from './services/common.service';
import { Location } from '@angular/common';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    // {
    //   title: 'Blank',
    //   url: 'blank',
    //   icon: 'storefront'
    // },
    {
      title: '主頁',
      url: 'home',
      icon: 'assets/icon/menu_home.svg',
      icon_selected: 'assets/icon/menu_home_selected.svg'
    },
    // {
    //   title: '現有租客',
    //   url: 'order',
    //   icon: 'assets/icon/menu_user.svg',
    //   icon_selected: 'assets/icon/menu_user_selected.svg'
    // }
  ];

  public appPages3 = [
    {
      title: '先租後買',
      url: 'rbb',
      icon: 'assets/icon/menu_rbb.svg',
      icon_selected: 'assets/icon/menu_rbb_selected.svg'
    },
    {
      title: '先租後買(設備)',
      url: 'rbb-equipment',
      icon: 'assets/icon/menu_rbb.svg',
      icon_selected: 'assets/icon/menu_rbb_selected.svg'
    },
    {
      title: '車牌',
      url: 'plate',
      icon: 'assets/icon/menu_plate.svg',
      icon_selected: 'assets/icon/menu_plate_selected.svg'
    }
  ];

  public appPages2 = [
    {
      title: '租務訂單',
      url: 'order',
      icon: 'assets/icon/menu_order.svg',
      icon_selected: 'assets/icon/menu_order_selected.svg'
    },
    {
      title: '車輛',
      url: 'car',
      icon: 'assets/icon/menu_car.svg',
      icon_selected: 'assets/icon/menu_car_selected.svg'
    },
    {
      title: '禁止預約',
      url: 'blocking',
      icon: 'assets/icon/menu_blocking.svg',
      icon_selected: 'assets/icon/menu_blocking_selected.svg'
    },

    {
      title: '停車場',
      url: 'parking',
      icon: 'assets/icon/menu_parking.svg',
      icon_selected: 'assets/icon/menu_parking_selected.svg'
    },
    {
      title: '按金',
      url: 'deposit',
      icon: 'assets/icon/menu_deposit.svg',
      icon_selected: 'assets/icon/menu_deposit_selected.svg'
    }
  ];

  public appPages4 = [
    {
      title: '用戶',
      url: 'user',
      icon: 'assets/icon/menu_user.svg',
      icon_selected: 'assets/icon/menu_user_selected.svg'
    },
    {
      title: '管理員通知',
      url: 'admin-notification',
      icon: 'assets/icon/menu_notification.svg',
      icon_selected: 'assets/icon/menu_notification_selected.svg'
    }
  ];

  public appPages5 = [
    {
      title: '預約維修',
      url: 'appointment',
      icon: 'assets/icon/menu_appointment.svg',
      icon_selected: 'assets/icon/menu_appointment_selected.svg'
    },
    {
      title: '維修項目',
      url: 'maintenance',
      icon: 'assets/icon/menu_maintenance.svg',
      icon_selected: 'assets/icon/menu_maintenance_selected.svg'
    },
    {
      title: '車房',
      url: 'factory',
      icon: 'assets/icon/menu_garage.svg',
      icon_selected: 'assets/icon/menu_garage_selected.svg'
    },
    {
      title: '交通違例',
      url: 'violation',
      icon: 'assets/icon/menu_violation.svg',
      icon_selected: 'assets/icon/menu_violation_selected.svg'
    },
    {
      title: '緊急支援',
      url: 'emergency',
      icon: 'assets/icon/menu_emergency.svg',
      icon_selected: 'assets/icon/menu_emergency_selected.svg'
    }
  ];

  public appPages9 = [
    {
      title: '設備(租借)',
      url: 'equipment',
      icon: 'assets/icon/menu_product.svg',
      icon_selected: 'assets/icon/menu_product_selected.svg'
    },
    {
      title: '加購品',
      url: 'product',
      icon: 'assets/icon/menu_product.svg',
      icon_selected: 'assets/icon/menu_product_selected.svg'
    },
    {
      title: '設備(租借)報表',
      url: 'equipment-report',
      icon: 'assets/icon/menu_product.svg',
      icon_selected: 'assets/icon/menu_product_selected.svg'
    },
    {
      title: '加購品報表',
      url: 'product-report',
      icon: 'assets/icon/menu_product.svg',
      icon_selected: 'assets/icon/menu_product_selected.svg'
    }
  ]

  public appPages6 = [
    {
      title: '通知',
      url: 'notification',
      icon: 'assets/icon/menu_notification.svg',
      icon_selected: 'assets/icon/menu_notification_selected.svg'
    }
  ];

  public appPages7 = [
    // {
    //   title: '數據',
    //   url: 'data',
    //   icon: 'assets/icon/menu_data.svg',
    //   icon_selected: 'assets/icon/menu_data_selected.svg'
    // },

    {
      title: '優惠券',
      url: 'coupon',
      icon: 'assets/icon/menu_coupon.svg',
      icon_selected: 'assets/icon/menu_coupon_selected.svg'
    },
    {
      title: '禮品',
      url: 'gift',
      icon: 'assets/icon/menu_gift.svg',
      icon_selected: 'assets/icon/menu_gift_selected.svg'
    },
    // {
    //   title: '按金客戶優惠',
    //   url: 'promotion',
    //   icon: 'assets/icon/menu_coupon.svg',
    //   icon_selected: 'assets/icon/menu_coupon_selected.svg'
    // },
    {
      title: '7-11 Coupon',
      url: 'seven-coupon',
      icon: 'assets/icon/menu_coupon.svg',
      icon_selected: 'assets/icon/menu_coupon_selected.svg'
    },
    {
      title: '活動',
      url: 'campaign',
      icon: 'assets/icon/menu_campaign.svg',
      icon_selected: 'assets/icon/menu_campaign_selected.svg'
    }
  ];

  public appPages8 = [
    {
      title: '管理員',
      url: 'admin',
      icon: 'assets/icon/menu_user.svg',
      icon_selected: 'assets/icon/menu_user_selected.svg'
    },
    {
      title: '主頁Banner',
      url: 'banner-setting',
      icon: 'assets/icon/menu_home.svg',
      icon_selected: 'assets/icon/menu_home_selected.svg'
    },
    {
      title: '常見問題',
      url: 'faq',
      icon: 'assets/icon/menu_faq.svg',
      icon_selected: 'assets/icon/menu_faq_selected.svg'
    },
    {
      title: 'Log',
      url: 'log',
      icon: 'assets/icon/menu_log.svg',
      icon_selected: 'assets/icon/menu_log_selected.svg'
    },
    {
      title: '系統設定',
      url: 'setting',
      icon: 'assets/icon/menu_setting.svg',
      icon_selected: 'assets/icon/menu_setting_selected.svg'
    },


    // {
    //   title: '問答',
    //   url: 'faq',
    //   icon: 'assets/icon/menu_faq.svg',
    //   icon_selected: 'assets/icon/menu_faq_selected.svg'
    // },



  ];

  public appPages10 = [
    {
      title: 'Forms',
      url: 'form-list',
      icon: 'assets/icon/menu_home.svg',
      icon_selected: 'assets/icon/menu_home_selected.svg'
    }];



  current_url = "";



  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private location: Location,
    public menuCtrl: MenuController,
    public commonService: CommonService,
    public authService: AuthService
  ) {
    // // Use matchMedia to check the user preference
    // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    // console.log(prefersDark.matches);
    // this.toggleDarkTheme(prefersDark.matches);
    // // Listen for changes to the prefers-color-scheme media query
    // prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));
    // // Add or remove the "dark" class based on if the media query matches

    let dark_mode = localStorage.getItem("dark_mode");
    if (dark_mode != undefined || dark_mode != null) {
      this.commonService.dark_mode = (dark_mode == 'false' ? false : true);
      document.body.classList.toggle('dark-theme', (dark_mode == 'false' ? false : true));
      // this.commonService.toggleDarkTheme();
    }

    this.initializeApp();


    this.router.events.subscribe(val => {
      if (location.path() != "") {
        this.current_url = this.router.url;
        // console.log(this.current_url);
        this.selectedIndex = this.appPages.findIndex(d => d.url == location.path());
      }
    });
  }

  toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark-theme', shouldAdd);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {

    // setTimeout(() => {
    //   console.log(123);
    //   this.toggleDarkTheme(false);
    // }, 5000);
  }

  refresh() {
    location.reload();
  }

}
