import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvPipe } from '../pipes/env.pipe';



@NgModule({
  declarations: [EnvPipe],
  imports: [
    CommonModule
  ],
  exports: [EnvPipe]
})
export class ShareModule { }
