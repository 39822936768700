import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {

  constructor(
    private httpService: HttpService,
    private commonService: CommonService,
    private router: Router,
    private authService: AuthService
  ){

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    let token = this.authService.isAuthenticated();
    let admin_data = this.authService.getAdminData();
    // console.log(token);
    // console.log(admin_data);
    if (token == undefined || token == null ){
      this.authService.Logout();
      return false;
    }
    else{
      // console.log(admin_data);
      // this.httpService.VerifyStoreAccount(admin_data.email, admin_data.latest_update_date).then(res => {
      //   // console.log(res);
      //   if (res.result != "success"){
      //     this.authService.Logout();
      //   }
      //   else{
      //     this.authService.updateUserData(res.data);
      //   }
      // });
      return true;
    }
  }
}
